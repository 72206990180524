import React from 'react'
import { graphql } from 'gatsby'
import { Flex } from 'baselift'
import { useTheme } from 'emotion-theming'
import { Content } from '../components/content'
import { useBreakpoint } from '../hooks/use-breakpoint'
import { processDocsPageHtml } from '../helpers/html-helpers'
import { Layout } from '../layouts'
import { Seo } from '../components/seo'
import { pageTemplateStyle } from '../styles/page-template-style'

const DocsPageTemplate = ({ data }) => {
  const {
    title,
    type,
    body: {
      childMarkdownRemark: { html },
    },
  } = data.contentfulDocumentation

  const theme = useTheme()
  const breakpointify = useBreakpoint(theme.breakpoints)
  const finalHtml = processDocsPageHtml(html, type)

  return (
    <Layout>
      <Seo title={`Docs - ${title}`} description="Documentation for Railcam 2D, a versatile camera plugin for Unity" />
      <Flex
        css={breakpointify({
          marginLeft: [0, 0, 0, theme.unit(5), 0],
          transition: 'margin-left 0.25s cubic-bezier(0.5, 0, 0.25, 1)',
        })}
      >
        <Content css={breakpointify(pageTemplateStyle)} dangerouslySetInnerHTML={{ __html: finalHtml }} />
      </Flex>
    </Layout>
  )
}

export default DocsPageTemplate

export const query = graphql`
  query DocumentationBySlug($slug: String!) {
    contentfulDocumentation(slug: { eq: $slug }) {
      slug
      title
      type
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
